<template>
  <div
    v-if="props.user.roles != 'admin_priti'"
    :style="`${
      !isOnboarded
        ? 'opacity: .8; backdrop-filter: blur(100px);'
        : 'opacity: 0; display: none;'
    } ${step ? 'z-index: 20;' : 'z-index: 30;'}`"
    class="tw-w-full tw-h-screen tw-fixed tw-backdrop-blur-sm tw-bg-black tw-transition-all tw-duration-500"
  ></div>

  <div
    v-if="props.user.roles != 'admin_priti'"
    :style="
      !isOnboarded && !step
        ? `width: 400px; transform: translate(-50%, -50%) scale(1);`
        : `width: 400px; transform: translate(-50%, -50%) scale(0);`
    "
    class="tw-z-30 tw-fixed tw-top-1/2 tw-left-1/2 tw-p-5 tw-bg-white tw-rounded-md tw-transition-all tw-duration-300"
  >
    <div class="tw-p-3 tw-grid tw-place-items-center tw-bg-blue-50 tw-mb-3">
      <img
        alt="Logo"
        src="~@/assets/images/logo.png"
        class="tw-w-24 tw-aspect-square tw-mx-auto tw-my-4"
      />
    </div>
    <div class="tw-px-2 tw-my-4">
      <h1 class="mb-2 tw-text-2xl tw-text-center tw-font-semibold">
        Selamat Datang di Salonial
      </h1>
      <p class="tw-text-center">
        Sebelum memulai, berikut ini adalah beberapa langkah yang harus
        dilengkapi
      </p>
    </div>
    <hr style="margin: 3px -16px" />
    <!-- checkbox -->
    <div class="tw-flex tw-items-start tw-gap-2 tw-mb-2 tw-mt-2">
      <input
        type="checkbox"
        v-model="is_onboarded"
        @change="setOnboarded()"
        class="tw-h-5 tw-w-5 tw-rounded tw-border-2 tw-border-gray-200"
      />
      <label class="tw-text-sm" for="">Jangan tampilkan lagi</label>
    </div>
    <div class="tw-flex tw-gap-4 tw-mt-3">
      <button
        @click="$emit('skip')"
        class="tw-flex-1 tw-px-3 tw-font-medium tw-py-2 tw-rounded tw-border-2 tw-border-gray-200"
      >
        Skip
      </button>
      <Button
        @click.once="$emit('next')"
        class="tw-flex-1 tw-font-medium tw-rounded"
        label="Pelajari"
      />
    </div>
  </div>
</template>

<script setup>
import { inject, defineProps, ref } from "vue";
import axios from "axios";

const isOnboarded = inject("isOnboarded");
const step = inject("step");
const is_onboarded = ref(false);

const props = defineProps({
  user: Object,
});

const setOnboarded = async () => {
  const request = {
    user_id: props.user.user_id,
    is_onboarded: is_onboarded.value,
  };

  try {
    const response = await axios.post(
      `${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/auth/set-onboardeds`,
      request
    );

    if (response.status === 200) {
      console.info("Onboarding status updated");
    }
  } catch (error) {
    console.error("Error updating onboarding status:", error);
  }
};
</script>
