<template>
  <ul v-if="items" class="tw-flex-1 tw-overflow-y-auto">
    <template v-for="(item, i) of items">
      <li
        v-if="visible(item) && !item.separator"
        :key="item.label || i"
        :class="[
          {
            'layout-menuitem-category': root,
            'active-menuitem': activeIndex === i && !item.to && !item.disabled,
          },
        ]"
        role="presentation"
      >
        <template v-if="root">
          <div
  :style="{
    display: step - 1 === i && !isOnboarded ? 'flex' : 'none',
    height: 'max-content',
    transform: 'translate(-50%, 0)',
    top: step === 1 ? '15rem' : step === 2 ? '20rem' : '22rem',
  }"
  class="tw-bg-white tw-p-3 tw-rounded-lg tw-fixed tw-flex tw-flex-col tw-gap-2 tw-left-1/2 tw--translate-x-1/2 tw-z-10 tw-shadow-md"
>
  <h1 class="tw-font-bold">{{ item.label }}</h1>
  <p style="width: 50ch" class="tw-text-sm tw-text-gray-500">
    {{ item.description }}
  </p>
  <div class="tw-flex tw-justify-between">
    <div class="tw-flex tw-justify-center tw-items-center tw-space-x-2">
      <div
        v-for="index in 3"
        :key="index"
        :class="{
          'tw-bg-gray-500 tw-text-white': index === step,
          'tw-bg-gray-200 tw-text-gray-600': index !== step,
        }"
        class="tw-h-3 tw-w-3 tw-rounded-full tw-flex tw-justify-center"
      ></div>
    </div>
    <div>
      <Button
        v-if="step > 1"
        icon="pi pi-arrow-left"
        @click="back()"
        class="tw-font-medium tw-bg-white border-1 tw-border-gray-200 mr-2"
        size="sm"
        style="color: black; transition: color 0.3s"
        :class="{ 'hover:text-white': step > 1 }"
      />
      <Button
        :icon="step < 3 ? 'pi pi-arrow-right' : ''"
        @click="$parent.$emit('next')"
        class="tw-font-medium"
        :label="step === 3 ? 'Selesai' : 'Selanjutnya'"
        size="sm"
        iconPos="right"
      />
    </div>
  </div>
</div>

          
          <div class="layout-menuitem-root-text" :aria-label="item.label">
            {{ $parent.$parent.$data.openSideNav ? item.label : "" }}
          </div>
          <appsubmenu
            :items="visible(item) && item.items"
            @menuitem-click="$emit('menuitem-click', $event)"
          >
          </appsubmenu>
        </template>

        <template v-else>
          <div class="" style="margin-left: -0.5rem;">
            <router-link
              v-if="item.to"
              :to="item.to"
              :class="[item.class, 'p-ripple', { 'p-disabled': item.disabled }]"
              :style="item.style"
              @click="onMenuItemClick($event, item, i)"
              :target="item.target"
              :aria-label="item.label"
              exact
              role="menuitem"
              v-ripple
            >
              <i style="font-size: 1.35rem" :class="item.icon"></i>
              <span class="menu-label">{{ item.label }}</span>
              <i
                v-if="item.items"
                class="pi pi-fw pi-angle-down menuitem-toggle-icon before:tw-text-lg"
              >
              </i>
              <Badge v-if="item.badge" :value="item.badge"></Badge>
            </router-link>

            <a
              v-if="!item.to"
              :href="item.url || '#'"
              :style="item.style"
              :class="[item.class, 'p-ripple', { 'p-disabled': item.disabled }]"
              @click="onMenuItemClick($event, item, i)"
              :target="item.target"
              :aria-label="item.label"
              role="menuitem"
              v-ripple
            >
              <i style="font-size: 1.35rem" :class="item.icon"></i>
              <span
                class="menu-label"
                v-if="$parent.$parent.$parent.$data.openSideNav"
                >{{ item.label }}</span
              >
              <i
                v-if="item.items"
                class="pi pi-fw pi-angle-down menuitem-toggle-icon before:tw-text-lg"
              >
              </i>
              <Badge v-if="item.badge" :value="item.badge"></Badge>
            </a>

            <transition name="layout-submenu-wrapper">
              <appsubmenu
                v-show="activeIndex === i"
                :items="visible(item) && item.items"
                @menuitem-click="$emit('menuitem-click', $event)"
              >
              </appsubmenu>
            </transition>
          </div>
        </template>
      </li>

      <li
        class="p-menu-separator"
        :style="item.style"
        :key="'separator' + i"
        role="separator"
        v-if="visible(item) && item.separator"
      ></li>
    </template>
  </ul>
</template>
<script>
import { inject } from "vue";

export default {
  name: "appsubmenu",
  props: {
    items: Array,
    root: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeIndex: null,
    };
  },
  setup() {
    const step = inject("step");
    const isOnboarded = inject("isOnboarded");

    return { step, isOnboarded };
  },
  methods: {
    onMenuItemClick(event, item, index) {
      if (item.disabled) {
        event.preventDefault();
        return;
      }

      if (!item.to && !item.url) {
        event.preventDefault();
      }

      //execute command
      if (item.command) {
        item.command({ originalEvent: event, item: item });
      }

      this.activeIndex = index === this.activeIndex ? null : index;

      this.$emit("menuitem-click", {
        originalEvent: event,
        item: item,
      });
    },
    visible(item) {
      if (!item) return;

      return typeof item.visible === "function"
        ? item.visible()
        : item.visible !== false;
    },

    back() {
      this.step -= 1;
    },
  },
};
</script>

<style scoped></style>
